import { delay, http, HttpResponse } from 'msw';
import { vectorBulkUploadError, vectorBulkUploadSuccess } from './data/vectors';

const bulkVectorUpload = http.post(
  '*/api/v1/orgs/:oid/:pid/bulk-vector-upload/:geneId',
  async ({ params }) => {
    await delay(1000);
    const geneId = params.geneId as string;
    if (geneId.endsWith('1')) {
      return HttpResponse.json(vectorBulkUploadSuccess, { status: 200 });
    }
    return HttpResponse.json(vectorBulkUploadError, { status: 400 });
  },
);

export const vectorHandlers = [bulkVectorUpload];
