import { SearchField } from '@formbio/ui/components/TextField/SearchField';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

function useDebouncedSearch(
  callback: (val: string) => void,
  debounceInterval: number,
) {
  const debouncedSearchHandler = useMemo(() => {
    if (callback) {
      return debounce(callback, debounceInterval);
    }
  }, [callback]);

  useEffect(() => () => debouncedSearchHandler?.cancel(), []); // cancel the debounce on unmount

  return debouncedSearchHandler;
}

export default function DebouncedSearchBox({
  externalValueDep = '', // used if there are situations the value is controlled externally, i.e. url param or some action should clear search
  onDebouncedSearchCallback,
  debounceInterval = 500,
  disabled,
  helperText,
  validation,
  className,
  placeholder,
  showStartIcon = true,
  autoFocus = false,
}: {
  externalValueDep?: string;
  onDebouncedSearchCallback: (search: string) => void;
  debounceInterval?: number;
  disabled?: boolean;
  helperText?: string;
  validation?: (_value: string) => boolean;
  className?: string;
  placeholder?: string;
  showStartIcon?: boolean;
  autoFocus?: boolean;
}) {
  const [hasError, setHasError] = useState(false);
  const [value, setValue] = useState(externalValueDep);
  const debouncedSearchHandler = useDebouncedSearch(
    onDebouncedSearchCallback,
    debounceInterval,
  );

  useEffect(() => {
    if (externalValueDep !== value) {
      setValue(externalValueDep);
    }
  }, [externalValueDep]);

  function onChange(val: string) {
    setValue(val);
    const isValid = validation ? validation(val) : true;
    setHasError(!isValid);
    if (isValid && debouncedSearchHandler) {
      debouncedSearchHandler(val);
    }
  }

  return (
    <SearchField
      disabled={disabled}
      value={value}
      onChange={event => onChange(event.target.value)}
      clearValue={() => onChange('')}
      placeholder={placeholder}
      helperText={validation ? hasError && helperText : helperText}
      error={hasError}
      className={className}
      showStartIcon={showStartIcon}
      autoFocus={autoFocus}
    />
  );
}
