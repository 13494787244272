import type {} from '@mui/lab/themeAugmentation'; // this import allows the components section to recognize "lab" components until it gets baked in in v5
import {
  createTheme,
  responsiveFontSizes,
  Shadows,
  Theme,
} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation'; // this import allows the components section to recognize MuiDataGrid components
import { componentsDark, default as componentsFormbio } from './components';
import { default as paletteFormbio, paletteDark } from './palette';
import {
  default as typographyFormbio,
  typographyDark,
  FontFamilies,
  defaultFontFamilies,
} from './typography';

import { ExtendedPalette, ExtendedTypography } from './type-extension';
import { info } from './palette/info';
import { SCROLLBAR_SIZE, SCROLLBAR_RADIUS } from './constants';

declare module '@mui/material/styles' {
  // this actually adds custom overrides to the Theme interface
  interface Theme {
    palette: ExtendedPalette;
    typography: ExtendedTypography;
  }
}

type FormbioTheme = (fontFamilies?: FontFamilies) => Theme;

/**
 * Adds the info shadow as shadows[20] so that it can be used in the theme
 * @param themeShadows the default shadows from the theme
 */
function customizeLastShadow(themeShadows: Shadows) {
  return [
    ...themeShadows.slice(0, 20),
    `0px 0px 0px 4px ${info[300]}`,
    ...themeShadows.slice(21),
  ] as Shadows;
}

/**
 * A set of reusable global styles that don't need
 * to be re-declared in styled components.
 * Eg. pt-1 for padding-top with spacing(1), m-2 for margin with spacing(2), flex, justify-center, etc.
 */
function getGlobalStyles(themeParam: Omit<Theme, 'components'>) {
  const sizes: Array<'half' | number> = ['half', 1, 2, 3, 4];
  const sizeBasedStyles = sizes.reduce(
    (acc, size) => {
      const sizeValue = size === 'half' ? 0.5 : size;
      // padding
      acc[`.p-${size}`] = {
        padding: themeParam.spacing(sizeValue),
      };
      acc[`.pt-${size}`] = {
        paddingTop: themeParam.spacing(sizeValue),
      };
      acc[`.pr-${size}`] = {
        paddingRight: themeParam.spacing(sizeValue),
      };
      acc[`.pb-${size}`] = {
        paddingBottom: themeParam.spacing(sizeValue),
      };
      acc[`.pl-${size}`] = {
        paddingLeft: themeParam.spacing(sizeValue),
      };
      acc[`.px-${size}`] = {
        paddingLeft: themeParam.spacing(sizeValue),
        paddingRight: themeParam.spacing(sizeValue),
      };
      acc[`.py-${size}`] = {
        paddingTop: themeParam.spacing(sizeValue),
        paddingBottom: themeParam.spacing(sizeValue),
      };
      // margin
      acc[`.m-${size}`] = {
        margin: themeParam.spacing(sizeValue),
      };
      acc[`.mt-${size}`] = {
        marginTop: themeParam.spacing(sizeValue),
      };
      acc[`.mr-${size}`] = {
        marginRight: themeParam.spacing(sizeValue),
      };
      acc[`.mb-${size}`] = {
        marginBottom: themeParam.spacing(sizeValue),
      };
      acc[`.ml-${size}`] = {
        marginLeft: themeParam.spacing(sizeValue),
      };
      acc[`.mx-${size}`] = {
        marginLeft: themeParam.spacing(sizeValue),
        marginRight: themeParam.spacing(sizeValue),
      };
      acc[`.my-${size}`] = {
        marginTop: themeParam.spacing(sizeValue),
        marginBottom: themeParam.spacing(sizeValue),
      };
      // gap
      acc[`.gap-${size}`] = {
        gap: themeParam.spacing(sizeValue),
      };
      return acc;
    },
    {} as Record<string, React.CSSProperties>,
  );
  return {
    ...sizeBasedStyles,
    '.flex': { display: 'flex' },
    '.flex-1': { flex: 1 },
    '.v-flex': { display: 'flex', flexDirection: 'column' },
    '.justify-center': { justifyContent: 'center' },
    '.justify-between': { justifyContent: 'space-between' },
    '.justify-start': {
      justifyContent: 'start',
    },
    '.justify-end': {
      justifyContent: 'end',
    },
    '.align-start': { alignItems: 'start' },
    '.align-center': { alignItems: 'center' },
    '.info': {
      color: themeParam.palette.info.main,
    },
    '.warning': {
      color: themeParam.palette.warning.main,
    },
    '.error': {
      color: themeParam.palette.error.main,
    },
    '.ellipsis': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '.border': {
      border: `1px solid ${themeParam.palette.divider}`,
      borderRadius: themeParam.shape.borderRadius,
    },
  };
}

export const themeFormbio: FormbioTheme = (
  fontFamilies = defaultFontFamilies,
) => {
  const theme = createTheme({
    components: {
      ...componentsFormbio,
      MuiCssBaseline: {
        styleOverrides: themeParam => ({
          '&::-webkit-scrollbar': {
            width: SCROLLBAR_SIZE,
            height: SCROLLBAR_SIZE,
            minWidth: '0.5vmax',
            minHeight: '0.5vmax',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeParam.palette.primary[400],
            outline: themeParam.palette.primary[400],
            borderRadius: SCROLLBAR_RADIUS,
            border: `2px solid transparent`,
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            border: `1px solid transparent`,
          },
          // for components with dark backgrounds in the light theme
          '.dark-scroll': {
            '&::-webkit-scrollbar-thumb ': {
              backgroundColor: themeParam.palette.primary[700],
              outline: themeParam.palette.primary[700],
            },
          },
          ...getGlobalStyles(themeParam),
        }),
      },
    },
    typography: typographyFormbio(fontFamilies),
    palette: paletteFormbio,
  });
  theme.shadows = customizeLastShadow(theme.shadows);
  return responsiveFontSizes(theme);
};

export const themeFormbioDark: FormbioTheme = (
  fontFamilies = defaultFontFamilies,
) => {
  const theme = createTheme({
    // fallback to regular light formbio components
    components: {
      ...componentsFormbio,
      ...componentsDark,
      MuiCssBaseline: {
        styleOverrides: themeParam => ({
          '&::-webkit-scrollbar': {
            width: SCROLLBAR_SIZE,
            height: SCROLLBAR_SIZE,
            minWidth: '0.5vmax',
            minHeight: '0.5vmax',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeParam.palette.primary[700],
            outline: themeParam.palette.primary[700],
            borderRadius: SCROLLBAR_RADIUS,
            border: `2px solid transparent`,
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            border: `1px solid transparent`,
          },
          ...getGlobalStyles(themeParam),
        }),
      },
    },
    typography: typographyDark(fontFamilies),
    palette: paletteDark,
  });
  theme.shadows = customizeLastShadow(theme.shadows);
  return responsiveFontSizes(theme);
};

// to keep all the static imports working for now.
// this will be removed in the future.
export default themeFormbio;

//height taken by the top toolbar, the breadcrumb and the navigation tabs
export const toolbarStyle = {
  height: {
    normal: 80,
    dense: 48,
  },
};
